import { lazy } from "react";

// Import components using lazy loading
const Dashboard = lazy(() => import("../component/Dashboard/Dashboard"));
const Student = lazy(() => import("../component/Student/Student"));
const Banner = lazy(() => import("../component/Banner/Banner"));
const Teacher = lazy(() => import("../component/Teacher/Teacher"));
const Profile = lazy(() => import("../component/Profile/Profile"));
const Transaction = lazy(() => import("../component/Transaction/Transaction"));
const Help = lazy(() => import("../component/Help/Help"));

// Define routes
const routes = [
  {
    path: "/dashboard", // the url
    component: Dashboard, // view rendered
    service: true,
  },
  {
    path: "/student", // the url
    component: Student, // view rendered
    service: true,
  },
  {
    path: "/teacher", // the url
    component: Teacher, // view rendered
    service: true,
  },
  {
    path: "/profile", // the url
    component: Profile, // view rendered
    service: true,
  },
  {
    path: "/transaction", // the url
    component: Transaction, // view rendered
    service: true,
  },
  {
    path: "/banner", // the url
    component: Banner, // view rendered
    service: true,
  },
  {
    path: "/help", // the url
    component: Help, // view rendered
    service: true,
  },
];

export default routes;
