import { RxDashboard } from "react-icons/rx";
import { PiStudentBold } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { BsBank } from "react-icons/bs";
import { MdOutlineLiveHelp } from "react-icons/md";
import { BsFilePost } from "react-icons/bs";

const SideBarRoute = [
  {
    path: "/app/dashboard",
    img: <RxDashboard />,
    name: "Dashboard",
    heading: "",
  },
  {
    path: "/app/student",
    img: <PiStudentBold />,
    name: "Student",
    heading: "",
  },
  {
    path: "/app/teacher",
    img: <GiTeacher />,
    name: "Teacher",
    heading: "",
  },
  {
    path: "/app/transaction",
    img: <BsBank />,
    name: "Transaction",
    heading: "",
  },
  {
    path: "/app/banner",
    img: <BsFilePost />,
    name: "Banner",
    heading: "",
  },
  {
    path: "/app/help",
    img: <MdOutlineLiveHelp />,
    name: "Help",
    heading: "",
  },
];

export default SideBarRoute;
